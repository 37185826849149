import React from "react";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import "./i18n";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-WSBJ3MP'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MainRoutes/>
    </BrowserRouter>
  </React.StrictMode>
);
